<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" lg="4">
        <label class="label-input">{{ $t("e-approve.label_select") }}</label>
        <v-select :items="todoStatus" outlined dense item-value="value" item-text="name" v-model="status"
          @change="searchFilterItem()"></v-select>
      </v-col>
      <v-col cols="12" md="4" lg="4">
        <v-btn class="mt-md-7 mr-md-2 btn-create lfont btn" color="info" @click="onCreate">
          <v-icon left>
            mdi-file-document
          </v-icon>
          {{ $t("taskAllocation.btn_create") }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <div class="block-content">
            <table class="table table-view" v-if="listEmployeeToDoList.length > 0">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.assignee") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.jobtitle") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.jobdescription") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.start") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.deadline") }}
                  </th>
                  <th class="text-left">
                    {{ $t("taskAllocation.table.status") }}
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr v-for="(item, index) in listEmployeeToDoList" :key="index">
                  <td>
                    {{ item.assignee.name }}
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.start_date }}</td>
                  <td>{{ item.deadline }}</td>
                  <td>
                    <span v-if="item.status == 'pending'">
                      <v-chip small style="background-color: rgb(102, 104, 102) !important;color:white !important">
                        {{ $t("taskAllocation.status.wait_for_response") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'confirm'">
                      <v-chip small style="background-color: rgb(37, 184, 37) !important;color:white !important">
                        {{ $t("taskAllocation.status.received") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'updated'">
                      <v-chip small style="background-color: rgb(25, 16, 84) !important;color:white !important">
                        {{ $t("taskAllocation.status.updated") }}
                      </v-chip>
                    </span>
                    <span v-if="item.status == 'success'" style="color: green;">
                      {{ $t("taskAllocation.status.success") }}
                    </span>
                    <span v-if="item.status == 'rejected'">
                      <v-chip small style="background-color: red !important;color:white !important">
                        {{ $t("taskAllocation.status.rejected") }}
                      </v-chip>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <defaultTableNoResult v-else />
            <Loading v-if="isLoading" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
  },
  data() {
    return {
      status: "all",
      isLoading: false,
      listEmployeeToDoList: [],
      todoStatus: [
        {
          id: 0,
          name: this.$t("taskAllocation.status.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("taskAllocation.status.wait_for_response"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("taskAllocation.status.received"),
          value: "confirm",
        },
        {
          id: 3,
          name: this.$t("taskAllocation.status.updated"),
          value: "updated",
        },
        {
          id: 4,
          name: this.$t("taskAllocation.status.success"),
          value: "success",
        },
        {
          id: 5,
          name: this.$t("taskAllocation.status.rejected"),
          value: "rejected",
        },
      ],
    };
  },
  methods: {
    onCreate() {
      this.$router
        .push({
          name: "employee_task_allocation.add",
        })
        .catch(() => { });
    },
    searchFilterItem() {
      this.fetchEmployeeToDoList();
      // this.pagination.current_page = 1;
    },
    fetchEmployeeToDoList() {
      this.isLoading = true;
      const items = {
        status: this.status,
        start_date: "",
        end_date: "",
        // filter: this.filter,
      };
      this.$axios
        .post(`/employee/list/todo/lists`, items)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            this.isLoading = false;
            this.listEmployeeToDoList = res.data.data;
            // this.pagination = res.data.data.pagination;
            // if (!this.listEmployeeToDoList.length > 0) {
            //   this.pagination.current_page = this.pagination.current_page - 1;
            // }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeToDoList();
  },
};
</script>

<style lang="scss" scoped>
.table-body {
  tr {
    td {
      text-wrap: balance !important;
    }
  }
}

.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}
</style>
